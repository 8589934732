import {useSeoMeta} from '#imports';
import {useRootStore} from '@/stores/root';

export const useApiMeta = (useCustomMeta = false) => {
  const rootStore = useRootStore();

  useSeoMeta(() => {
    const meta = useCustomMeta ? rootStore.meta : rootStore.generalMeta;

    if (!meta) {
      return {};
    }

    const {title, description, keywords, image, imgAlt} = meta;

    return {
      title,
      description,
      keywords,
      ogTitle: title,
      ogDescription: description,
      ogImage: image,
      ogImageAlt: imgAlt,

      // `http-equiv="Last-Modified"` meta tag is ignored by search engines
    };
  });
};
